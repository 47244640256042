import { useLayoutEffect } from 'preact/hooks';
import { initSearchStore, sendSearchAnalytics } from 'lib/dot/search/utils.ts';
import { PageType } from 'lib/dot/enum/pageType.ts';
import { SessionStorage, SessionStorageKeys } from 'utils/SessionStorage.ts';
import { DOTHelper } from 'lib/dot/DOTHelper.ts';
import { filters as globalFilters } from 'signals/filters';
import { useSignals } from 'context/SignalsContext.tsx';
import { resolveLocality } from 'service/locality/resource';
import { onIslandLoaded } from 'utils/islandUtils.ts';
import { sendBazarImpression } from 'lib/dot/bazar/utils.ts';
import { availableCategories } from 'components/filters/categoryList/signals.ts';
import { activeCategory } from 'signals/category';
import { getAnalyticsCategoryAmount } from 'service/category/utils.ts';

export type Props = {
	itemCount: number;
	myProfile: boolean;
	sellerFilter: boolean;
	sellerId: string | number;
	usedGoods: boolean;
};

export function DotBazar({ myProfile, usedGoods, sellerFilter, sellerId, itemCount }: Props) {
	const { filters = globalFilters } = useSignals();

	useLayoutEffect(() => {
		DOTHelper.initPageType(myProfile ? PageType.SEARCH_RESULTS_SELLER_SELF : PageType.SEARCH_RESULTS_SELLER);
		const searchMeta = SessionStorage.getSearchMeta();
		SessionStorage.removeItem(SessionStorageKeys.SEARCH_META);

		initSearchStore({
			itemCount,
			myProfile,
			searchType: searchMeta?.searchType,
			sellerFilter,
			sellerId,
			usedGoods: searchMeta?.usedGoods ?? usedGoods,
		});

		// Search analytics should be sent only after triggering a search while on the site, meaning just loading into
		// this page should not trigger the analytics. However, since the app is not fully SPA, sometimes following
		// a link (such as show other seller's offers) is supposed to send the analytics, however we have no data about
		// the resulting search at the page with the link, so we store the fact that we are interested in the resulting
		// search in SessionStorage in searchMeta. If the searchMeta is present, we send the analytics at load.
		// We cannot send the analytics until the island loads and setups some data for the analytics, namely the
		const removeListener = onIslandLoaded('BazarIsland', () => {
			const categoryCount = getAnalyticsCategoryAmount(availableCategories.peek(), activeCategory.peek()?.id);

			if (searchMeta) {
				const _filters = filters.peek();

				if (_filters.locality?.id) {
					resolveLocality({
						entity_id: _filters.locality.id,
						entity_type: _filters.locality.type,
					})
						.then((resolvedLocality) => {
							sendSearchAnalytics({
								categoryCount: categoryCount,
								filters: _filters,
								itemCount: itemCount,
								resolvedLocality: resolvedLocality,
								searchType: searchMeta.searchType,
								tab: searchMeta.tab,
							});
						})
						.catch();
				} else {
					sendSearchAnalytics({
						categoryCount: categoryCount,
						filters: _filters,
						itemCount: itemCount,
						searchType: searchMeta.searchType,
						tab: searchMeta.tab,
					});
				}
			}

			sendBazarImpression(itemCount);
		});

		return removeListener;
	}, []);
}
